
import HeroImg from './assets/img/hero.jpg';
import Logo from './assets/img/bureau-koorenhof.svg'
type IconProps = {className: string, "aria-hidden": any}


const logos = [
    { name: 'Samfe', url: 'https://samfe.nl/Samfe_Logo.png' },
    { name: 'Jens Defense', url: 'https://jimdo-storage.freetls.fastly.net/image/147920326/5d69bd51-764e-4e5e-8309-e9cb0a2509e6.jpg' },
]

const footerNavigation = {
    main: [
        { name: 'KvK: 80738745'},
        { name: 'IBAN: NL68INGB0007190376'},
        { name: 'Gevestigd te Almere'},
    ],
}

export default function Home() {
    return (
        <div className="bg-white">
            <main>
                {/* Hero section */}
                <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:pt-12 lg:pb-48">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                        <div>
                            <div>
                                <img
                                    className="h-32 w-auto"
                                    src={Logo}
                                    alt="Bureau Koorenhof"
                                />
                            </div>
                            <div className="mt-16 md:mt-44">

                                <div className="mt-6 sm:max-w-xl text-left">
                                    <h1 className="text-4xl font-extrabold text-navy tracking-tight sm:text-5xl">
                                        Administratie &<br />Virtual Assistance
                                    </h1>
                                    <p className="mt-6 text-xl text-gray-700">
                                        Breed inzetbare ondersteuning voor o.a.
                                        <br />
                                        <span className={'text-ocean'}>assistentie</span>&thinsp; en
                                        &thinsp;<span className={'text-ocean'}>administratieve dienstverlening</span>.

                                    </p>
                                </div>

                                <div className="md:py-16">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hidden lg:block sm:mx-auto sm:max-w-3xl sm:px-6">
                        <div className="py-12 sm:relative sm:mt-12 sm:py-4 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">

                            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                                <img
                                    className="w-full  lg:h-full lg:w-auto lg:max-w-none"
                                    src={HeroImg}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Logo cloud section */}
                <div className="mt-16 mb-32 md:mt-32 md:mb-48">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                            <div>
                                <h2 className="text-3xl font-extrabold text-navy tracking-tight sm:text-4xl text-left">
                                    Velen handen maken licht werk.
                                </h2>
                                <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-700 text-left">
                                    Bedrijfsvoering is een breed begrip.
                                    <br />
                                    Daarom is het fijn als u op uw core business kunt focussen.
                                    <br/><br />
                                    <strong className={'text-ocean'}>Bureau Koorenhof regelt de rest!</strong>

                                </p>
                            </div>
                            <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                                <div className="col-span-3 flex">
                                    <h2 className="text-2xl mb-6 font-extrabold text-navy tracking-tight sm:text-3xl text-left">
                                        Onze partners:
                                    </h2>
                                </div>
                                {logos.map((logo) => (
                                    <div key={logo.name} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img className="max-h-12" src={logo.url} alt={logo.name} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            {/* Footer section */}
            <footer className="mt-24 bg-navy sm:mt-12">
                <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        {footerNavigation.main.map((item) => (
                            <div key={item.name} className="px-5 py-2">
                                <span className="text-base text-gray-200">
                                    {item.name}
                                </span>
                            </div>
                        ))}
                    </nav>
                    <div className="mt-8 flex justify-center space-x-6">

                    </div>
                    <p className="mt-8 text-center text-base text-gray-400">&copy; { new Date().getFullYear() } Bureau Koorenhof - Alle rechten voorbehouden.</p>
                </div>
            </footer>
        </div>
    )
}